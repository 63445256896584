import React from "react";

export default function About() {
  return (
    <div className="light">
      <div>
        <h1 className="nav_app_title">
          <span>O NAS</span>
        </h1>

        <p className="about">
          Pasjonaci motoryzacji podchodzący do samochodów w sposób nietuzinkowy
          z nutą kreatywności do innowacyjnych rozwiązań. Stworzyliśmy aplikację
          PWA umożliwiającą tworzenie historii napraw pojazdów. Miejsce, w
          którym zapisywane są wszystkie istotne informacje o pojeździe co
          przyczynia się do zwiększenia kontroli nad stanem technicznym przez
          użytkowników. Mechanicy zyskali platformę do dokumentowania usług i
          budowania relacji z klientami. Motohistoria.pl to krok ku przyszłości
          motoryzacji, w której technologia wspiera nie tylko komfort
          użytkowania samochodów, ale również bezpieczeństwo.
        </p>
      </div>
    </div>
  );
}
